import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { map, Observable } from 'rxjs';
import { PermissionsService } from '@core/services/auth/permissions.service';

@Injectable({
  providedIn: 'root',
})
export class UserPermissionGuard {
  private router = inject(Router);
  private permissionsService = inject(PermissionsService);

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const requiredUserPermissions = route.data['requiredUserPermissions'];
    const redirectURL = route.data['redirectUrl'];

    if (requiredUserPermissions.length == 0) return true;

    return this.permissionsService.user_has_userPermission(requiredUserPermissions).pipe(
      map((result) => {
        if (result == false) {
          console.log('%c USER DOES NOT HAVE REQUIRED USER PERMISSION!', 'background: red; font-weight: bold;');
          this.router.navigate([redirectURL]);
        }
        return result;
      })
    );
  }
}
